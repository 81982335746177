/* Footer.css */

.footer {
  color: #fff; /* White text color */
  padding: 20px 0; /* Equal padding on top and bottom, 0 padding on left and right */
  position: fixed; /* Position the footer fixed */
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000; /* Ensure footer is above other content */
  display: flex; /* Apply flexbox layout */
  justify-content: center; /* Center align horizontally */
  align-items: center; /* Center align vertically */
}

.footer-container p {
  margin-right: 10px; /* Add spacing between text and icons */
  font-size: calc(12px + 0.02vw); /* Dynamic font size based on viewport width */
}

.social-icons {
  justify-content: center;
  display: flex; /* Apply flexbox layout */
  align-items: center; /* Center align vertically */
}

.social-icons a {
  color: #fff; /* White color for icons */
  font-size: 24px; /* Adjust icon size */
  margin-right: 8px; /* Add spacing between icons */
}
