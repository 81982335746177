/* PageContent.css */
.page-content {
    padding: 20px;
    background-color: #222; /* Dark background color */
    color: #fff; /* White text color */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .page-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .page-description {
    font-size: 16px;
    line-height: 1.5;
  }
  